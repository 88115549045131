.App {
  text-align: center;
}

.btn-primary {
  background-color: var(--themed-blue-900);
  color: var(--themed-white);
}

.App-header {
  //min-height: 100vh;
  //display: block;
  //flex-direction: column;
  //align-items: center;
  margin-top: 25px;
}

h1.welcome-header {
  font-size: 3.5em;
  font-weight: bold;
}

a.title-link {
  color: white;
  text-decoration: none;
}

a.title-link:hover {
  color: #b0b0b0;
  text-decoration: none;
}

a.title-link > owc-icon {
  color: white;
}

.toolbar {
  top: 0;
  left: 0;
  right: 0;
  min-height: 60px;
  display: flex;
  align-items: center;
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  justify-content: space-between;
}

img.toolbar-logo {
  margin-top: 5px;
  height: 40px;
}

div.toolbar-item {
  margin-left: 30px;
}

img.navify-button-logo {
  margin: 15px;
  width: 100px;
  display: inline-block;
}

img.hero-icon {
  width: 100px;
  //height: 100px;
}

img.dialog-hero-logo {
  width: 150px;
  margin-right: 25px;
  display: inline-block;
}
