@import "~@one/web-components/dist/owc/owc.css";
@import "~@one/icons/dist/one-icons-legacy.css";
@import "~@one/icons/dist/one-icons-filled.css";
@import "~@one/icons/dist/one-icons-outlined.css";
@import "~@one/design-tokens/css/legacy/light/variables.css";
@import "~@one/roche-font/style.css";

body {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0;
  /*   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  overflow: scroll;

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
